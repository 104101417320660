import {
    Container,
    Stack,
    Typography,
    Grid,
    Card,
    Box,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import CardMedia from "@mui/material/CardMedia";
  import { useNavigate } from 'react-router-dom';
  import { GetAll, apiURL, Put } from "../../../utils/apiCalls";
  
  
  const VisitingPoster = () => {
    const navigate = useNavigate();  
    const [category, setCategory] = useState([])
    const [slectcategory, setSelectcategory] = useState(1)
    const [posterdata,setPosterdata] = useState(1)
  
  
    const getcategory  = async () => {
      const response = await GetAll(apiURL + "/api/v1/posters/Allcategory");
      if (response && response.data && response.data.status) {
        setCategory(response.data.data);
        console.log(response.data.data);
      }
    };
    const getposter = async (e) => {
      
      const response = await GetAll(apiURL + `/api/v1/posters/categorytype/VISITING`);    
      if (response && response.data && response.data.status) {
        setPosterdata(response.data.data);
        console.log(response.data.data);
      }
    };
    useEffect(() => {
    //   getcategory();
     getposter(1)
    }, []);
    return (
      <Container sx={{ m: 0, width: "100%", maxWidth: "100%" }} maxWidth={false}>
        <Box mb={5}>
          <Stack pb={2} spacing={4} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="h4" gutterBottom>
            VISITING CARDS
            </Typography>
  
            {/* <FormControl sx={{width:'25%'}}>
    <InputLabel id="category">category</InputLabel>
    <Select
      labelId="category"
      id="category"
                label="Select"
                value={slectcategory}
                defaultValue={1}
                onChange={async (e) => {
                  setSelectcategory(e.target.value)
                await getposter(e.target.value)
      
                }}
              >
                {category?.map((data) => <MenuItem value={data.id} >{data.name }</MenuItem>)}     
    </Select>
  </FormControl> */}
     
            
        </Stack>
          <Grid container spacing={2}>
            {
              posterdata?.length>0 ?
              posterdata?.map((data) =>
              <Grid item xs={12} md={4}>
                <Card raised onClick={() => {
                  navigate(`/dashboard/visiting/${data.id}`)
                }}>
                  <CardMedia
                    component="img"
                      image={data.filepaths3}
                    alt={"alt"}
                    sx={{ objectFit: "fill", height: 240 }}
                  />
                </Card>
                </Grid>)
                : <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} sx={{width:'100%'}}>
                  <Box>
                    <Typography variant="h4">No data</Typography>
                  </Box>
                </Stack>
                
  
            }
  
          </Grid>
        </Box>
      </Container>
    );
  };
  
  export default VisitingPoster;
  