import React, { useRef, useState, useEffect } from "react";
import { saveAs } from "file-saver";
import imgEdit from '../../../img/agentposter.jpg'
import { GetAll, apiURL, Put } from "../../../utils/apiCalls";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import {
    Button,
    CardContent,
    CardActions,
    Card,
    TextField,
    Grid,
    Stack,

  } from "@mui/material";

const ImageTextReplacer = () => {
  const navigate = useNavigate();  
  const { id } = useParams();

  const canvasRef = useRef(null);
  const [newText, setNewText] = useState();
  const getposter = async (e) => {
    
    const response = await GetAll(apiURL + `/api/v1/posters/byid/${id}`);
    if (response && response.data && response.data.status) {
      
      setImagedata(response.data.data);
      console.log(response.data.data);
      return response.data.data
    }
    return {}
  };
  const [imagedata, setImagedata] = useState({})

  // Load and modify the image with text replacement
  useEffect(() => {
    const loadImage = async () => {
        const data = await getposter();

        if (data && data.signedUrl) {
            const img = new Image();
            img.src = data.signedUrl;
            img.crossOrigin = "anonymous"; // Prevent canvas from being tainted

          img.onload = () => {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;

            // Draw the image on the canvas
            ctx.drawImage(img, 0, 0);

            // Draw the new text on the image
            const imgData = data.image_data ? JSON.parse(data.image_data) : {};
            ctx.font = imgData.font;
            ctx.fillStyle = imgData.fillStyle; // Set your desired text color
            console.log(JSON.parse(sessionStorage.getItem('user_details')), "JSON.parse(sessionStorage.getItem('user_details'))")
            let user = JSON.parse(sessionStorage.getItem('user_details'))
            ctx.fillText(user.first_name + ' ' + user.last_name || imgData.defaultText,  imgData.x,  imgData.y); // Add defaultText if needed

            const designationText = `${user.designation} | ${user.phone}`; // Use "AGENT" if no designation provided
            ctx.font =  imgData.font2; // Change font size for designation
            ctx.fillText(designationText, imgData.x2,  imgData.y2);


            // const ctx1 = canvas.getContext("2d");
            // canvas.width = img.width;
            // canvas.height = img.height;
            // ctx.drawImage(img, 0, 0);
            // ctx1.font = imgData.font;
            // ctx1.fillStyle = imgData.fillStyle;
            // console.log(JSON.parse(sessionStorage.getItem('user_details')), "JSON.parse(sessionStorage.getItem('user_details'))")
            // ctx1.fillText("AGENT ", 33, 700); 
          };
        }
    };

    if (id) {
        loadImage();
    }
}, [id, newText]); 
  // Handle download
  const handleDownload = () => {
    const canvas = canvasRef.current;
    canvas.toBlob((blob) => {
      let user = JSON.parse(sessionStorage.getItem('user_details'))
      saveAs(blob, `${user.first_name + ' ' + user.last_name+' '+ new Date()
      }.png`); // Save the edited image
    });
  };

  return (
    <div>
   

      {/* <Grid item xs={4} spacing={2} padding={2}>
        <Grid>
        <h2>Custom Image</h2>
        </Grid>
        <Grid>
        <Button onClick={handleDownload}  variant='contained' style={{ marginTop: "10px" }} >
        Download Image
      </Button>
           </Grid>
              </Grid> */}
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} p={2} >
        <h2>Image</h2>

        <Button onClick={handleDownload}  variant='contained' style={{ marginTop: "10px" }} >
        Download Image
      </Button>
              </Stack>
      {/* Input to change the text */}
<Grid container>
            
           
              </Grid>
      {/* Canvas to display the image */}

      <canvas ref={canvasRef} style={{ marginTop: "10px" ,width:'100%'}}></canvas>

      {/* Button to download the edited image */}
    
   
    </div>
  );
};

export default ImageTextReplacer;
